import React from 'react';
import { SEGMENT_DYNAMISM } from '@pushologies/database-service/db/entities/segment';
import { useStoreState, useStoreActions } from '~store/hooks';
import { Button } from '~components/button';
import { SelectField, TextField } from '~components/inputs';
import ReloadIcon from '~images/reload.svg';
import { numberCommaFormat } from '~helpers/formats';
import { HeaderWrapperDiv, HeaderTopDiv, HeaderBottomDiv, IconSpan } from './styles';
import { SelectOption } from '../select';
import { hasIdRules } from './rule/validation';

export const INVALID_RULE = 'INVALID_RULE';
const dynamismOptions: SelectOption<SEGMENT_DYNAMISM>[] = [
  {
    name: 'Always',
    value: SEGMENT_DYNAMISM.Always
  },
  {
    name: 'One Time',
    value: SEGMENT_DYNAMISM.OneTime
  }
];

interface Props {
  columnAlignment?: boolean;
}

export const SegmentHeader: React.FC<Props> = ({ columnAlignment }) => {
  const [invalidName, setInvalidName] = React.useState(true);
  const [invalidDesc, setInvalidDesc] = React.useState(true);
  const [count, setCount] = React.useState(0);
  const segmentState = useStoreState(({ createSegment }) => createSegment);
  const isInvalid = useStoreState(({ createSegment }) => createSegment.isInvalid);
  const hasInvalidRules = useStoreState(({ createSegment }) => createSegment.hasInvalidRules);
  const { setModel, addInvalidRule, removeInvalidRule, saveSegment } = useStoreActions(
    ({ createSegment }) => createSegment
  );
  const { fetchCount } = useStoreActions(({ segments }) => segments);
  const { loading } = useStoreState(({ segments }) => segments);
  const disableDynamism = hasIdRules(segmentState.rules);

  const handleDynamismChange = (option: SelectOption<SEGMENT_DYNAMISM>[]) => {
    setModel({ dynamism: option[0]?.value });
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => setModel({ name: event.target.value });

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setModel({ description: event.target.value });

  const handleFetchCount = async () =>
    await fetchCount({
      rules: segmentState.rules.map(({ segmentRule }) => segmentRule),
      onSuccess(segmentCount) {
        setCount(segmentCount);
      }
    });

  React.useEffect(() => {
    if (segmentState.name?.length && segmentState.description?.length) removeInvalidRule(INVALID_RULE);
    else addInvalidRule(INVALID_RULE);
  }, [segmentState.name, segmentState.description]);

  React.useEffect(() => {
    if (!segmentState.name?.length) {
      setInvalidName(true);
    } else if (invalidName === true) {
      setInvalidName(false);
    }
  }, [segmentState.name]);

  React.useEffect(() => {
    if (!segmentState.description?.length) {
      setInvalidDesc(true);
    } else if (invalidDesc === true) {
      setInvalidDesc(false);
    }
  }, [segmentState.description]);

  React.useEffect(() => {
    if (disableDynamism && !!segmentState.dynamism) {
      setModel({ dynamism: undefined });
    }
  }, [disableDynamism]);

  return React.useMemo(
    () => (
      <HeaderWrapperDiv $columnAlignment={columnAlignment}>
        <HeaderTopDiv>
          {!columnAlignment && (
            <Button
              loading={segmentState.createSegmentLoading}
              onClick={saveSegment}
              disabled={isInvalid}
              testId="saveSegmentButton"
              tourId="segmentSave"
            >
              {segmentState.id ? 'update' : 'save'}
            </Button>
          )}
          <section className="userCount" data-tour="userCount">
            <h3 data-testid="segmentTotalUsers">{numberCommaFormat(count)} total subscribers</h3>
            <IconSpan
              $loading={loading === 'fetchingCount'}
              disabled={hasInvalidRules}
              onClick={!hasInvalidRules ? handleFetchCount : null}
              data-testid="reloadCountButton"
            >
              <ReloadIcon />
            </IconSpan>
          </section>
        </HeaderTopDiv>
        <HeaderBottomDiv data-tour="segmentMetadata">
          <TextField
            name="segmentName"
            label="name"
            value={segmentState.name}
            onChange={handleNameChange}
            invalid={invalidName}
          />
          <TextField
            name="segmentDescription"
            label="description"
            value={segmentState.description}
            onChange={handleDescriptionChange}
            invalid={invalidDesc}
          />
          <SelectField
            id="segmentDynamicRule"
            label="Dynamism"
            onChange={handleDynamismChange}
            options={dynamismOptions}
            value={dynamismOptions.filter((opt) => opt.value === segmentState.dynamism)}
            inValid={false}
            clearable
            disabled={disableDynamism}
          />
        </HeaderBottomDiv>
      </HeaderWrapperDiv>
    ),
    [
      segmentState.name,
      segmentState.description,
      segmentState.dynamism,
      segmentState.rules,
      count,
      loading,
      isInvalid,
      hasInvalidRules,
      segmentState.createSegmentLoading,
      invalidName,
      invalidDesc
    ]
  );
};
